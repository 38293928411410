const trillion = 1000000000000
const billion = 1000000000
const million = 1000000
const thousand = 1000

export default function shortenMoney(number:number) {
  if(number >= trillion) {
    return "$" + (number/trillion) + "T";
  }
  if(number >= billion) {
    return "$" + (number/billion) + "B";
  }
  if(number >= million) {
    return "$" + (number/million) + "M";
  }
  if(number >= thousand) {
    return "$" + (number/thousand) + "K";
  }

  return "$" + number;
}


export function shortenAndRoundMoney(number: number) {
  if(number >= trillion) {
    return "$" + Math.round(number/trillion) + "T";
  }
  if(number >= billion) {
    return "$" + Math.round(number/billion) + "B";
  }
  if(number >= million) {
    return "$" + Math.round(number/million) + "M";
  }
  if(number >= thousand) {
    return "$" + Math.round(number/thousand) + "K";
  }

  return "$" + Math.round(number);
}
