import React from 'react'

const Copyright = () => {
  return (
    <div>
      <h1>Copyright</h1>
      <p>This database was compiled from publicly available information gathered from the <a href="https://lobbyingdisclosure.house.gov/" target="_blank" rel="noopener noreferrer">United States Senate Lobbying Disclosure Act Database.</a> Visualizations from this data are released under <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noopener noreferrer">Creative Commons Attribution 4.0 International License</a> and may be freely reused with attribution to <a href="http://web.mit.edu/insong/www/research/lobbyview.bib" target="_blank" rel="noopener noreferrer">LobbyView</a></p>
    </div>
  )
}

export default Copyright
