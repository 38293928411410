import React, { Component } from 'react';
import PropTypes from 'prop-types'; // ES6
import { globalHistory } from '@reach/router'

import "./vizReport.scss";
import capitalize from "utils/capitalize/capitalize";
import formatMoney from "utils/formatMoney/formatMoney";
import mapIssueCode from "utils/mapIssueCode/mapIssueCode";
import parseUrlQuery from "utils/parseUrlQuery/parseUrlQuery";
// import objToQueryString from "utils/objToQueryString/objToQueryString";
import formatReportYearAndQuarter from "components/query/formatReportYearAndQuarter"

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import ReportsVizContainer from "./reportsVizContainer/ReportsVizContainer"
import WordCloudContainer from "components/viz/wordCloudContainer/WordCloudContainer"
import TimeSeriesContainer from "components/viz/timeSeriesContainer/TimeSeriesContainer"
import GovAgenciesContainer from "components/viz/govAgenciesContainer/GovAgenciesContainer"
import RevolvingDoorContainer from "components/viz/revolvingDoorContainer/RevolvingDoorContainer"
// import SankeyContainer from "components/viz/sankeyContainer/SankeyContainer"
import InHouseOutsourcedBarChart from 'components/viz/inHouseOutsourcedBarChart/InHouseOutsourcedBarChart';
import MathJax from 'components/mathJax/MathJax'

import isBrowser from "utils/isBrowser"

export default class VizReport extends Component {
  constructor(props) {
    super(props);

    const query = parseUrlQuery(isBrowser ? window.location.search.substring(1) : ""); //get the query in the url if any
    query.vizTab = query.vizTab || props.VIZ_KEYS.WORD_CLOUD; //default to word cloud if viz tab is not defined

    this.state = {
      tab: query.vizTab,
      width: 900, //used to make the visualizations responsive
      height: 400
    };

    this.tabsRef = React.createRef(); //ref used to get the width of the containing div that the visuzalizations can take up
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize); //add resize listener
    this.getWhichVizData(); //this is primarily used to set the vizTab query in the url
    this.resize();

    this.stopListening = globalHistory.listen(() => { //set event listener to change viz tabs
      const query = parseUrlQuery(window.location.search.substring(1)); //get the query in the url if any
      if(typeof query.vizTab === "string") { //if the viz tab is valid
        this.setState({ tab: query.vizTab }); //change tabs
      }
    })
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize); //remove resize listener
    this.stopListening()
  }

  //used to fetch new data and check resize https://reactjs.org/docs/react-component.html#componentdidupdate
  componentDidUpdate(prevProps, prevState) {
    //there are now repoerts data, when there wasn't any before (aka we got new reports data)
    if(this.props.reports.length !== prevProps.reports.length) {
      this.getWhichVizData(); //auto fetch data on the same tab
    }


    //check if we need to resize
    if(this.tabsRef.current) {
      if(this.tabsRef.current.clientWidth !== this.state.width) {
        // console.log("componentDidUpdate",this.tabsRef.current.clientWidth);
        this.resize();
      }
    }
  }

  getWhichVizData = () => {
    Object.keys(this.props.VIZ_KEYS).forEach(viz => {
      if(this.state.tab === this.props.VIZ_KEYS[viz]) { //if this is the viz we're looking at
        this.props.getVizData(this.props.VIZ_KEYS[viz]); //get data for this viz
      }
    })
  }

  //this function sets the new width that the reports can take up
  //how to get the width of a rendered element: https://stackoverflow.com/questions/43817118/how-to-get-the-width-of-a-react-element
  resize = e => {
    if(this.tabsRef.current) {
      this.setState({width: this.tabsRef.current.clientWidth});
    }
  }

  getVizContent = () => {
    if(this.state.tab === this.props.VIZ_KEYS.WORD_CLOUD) {
      return (
        <ReportsVizContainer heading="Content Analysis for Reports">
          <WordCloudContainer width={this.state.width} height={this.state.height} wordCloudData={this.props.wordCloudData} requestStatus={this.props.wordCloudRequestStatus}/>
        </ReportsVizContainer>
      )
    }
    else if(this.state.tab === this.props.VIZ_KEYS.TIME_SERIES) {
      const timeSeriesHeading = (
        <div>
          <div style={{float: "right"}}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  <div>
                    Lobbying expenses per issue in a year are caclulated by parsing reports disclosed in a given year by registered lobbying firms.
                    <br/><br/>
                    The lobbying expense <MathJax tex={'V_{jit}'}/> for lobbying firm <MathJax tex={'j'}/> on issue <MathJax tex={'i'}/> in the year <MathJax tex={'t'}/> is calculated as <MathJax tex={'V_{jit}=\\sum_{r}^{R_{tj}} \\frac{L_{jr}}{|I_r|}'}/> where <MathJax tex={'L_{r}'}/> is the total lobbying expense found on report <MathJax tex={'r'}/> and <MathJax tex={'I_r'}/> is the set of all legislative issues appearing in report <MathJax tex={'r'}/>. Each <MathJax tex={'V_{jt}'}/> is indicated by each colored stack in the above area chart for a given year <i>(explicitly for the top 7 issues across all years)</i>. Likewise, the total lobbying expense in a particular year, <MathJax tex={'V_{t}'}/>, over all legislative issues is <MathJax tex={'V_{t}=\\sum_{i}^{I}\\sum_{j}^{L}\\sum_{r}^{R_{tj}}\\frac{L_{jr}}{|I_r|}'}/>.
                  </div>
                </Tooltip>
              }>
              <Button variant="warning" style={{color: "white"}}>Methodology</Button>
            </OverlayTrigger>
          </div>
          <div>Annual Lobbying Expenses (Top 7 Issues)</div>
        </div>
      )
      // console.log("WIDTH", this.state.width)
      return (
        <ReportsVizContainer heading={timeSeriesHeading}>
          <TimeSeriesContainer width={this.state.width} height={this.state.height} yearIssueAmountData={this.props.yearIssueAmountData} requestStatus={this.props.yearIssueAmountRequestStatus}/>
        </ReportsVizContainer>
      )
    }
    // else if(this.state.tab === this.props.VIZ_KEYS.SANKEY) {
    //   return (
    //     <ReportsVizContainer heading="Lobbying Finance">
    //       <SankeyContainer width={this.state.width} height={this.state.height} sankeyData={this.props.sankeyData}/>
    //     </ReportsVizContainer>
    //   )
    // }
    else if(this.state.tab === this.props.VIZ_KEYS.IN_HOUSE_OUT_SOURCED) {
      const lobbyingBarChartHeading = (
        <div>
          <div className="userGuideButtonContainer">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  <div>
                  Click on the checkboxes or the bars themselves to toggle which elements are displayed in the chart.
                  <br/><br/>
                  Hover over each of the bars to observe the precise dollar amount and issue name each bar represents.
                  <br/><br/>
                  Hover over elements on the X-axis to see the full name of each issue code displayed in the chart.
                  <br/><br/>
                  Click and drag the slider button to change the amount of issues that are currently being displayed.
                  </div>
                </Tooltip>
              }>
              <Button variant="warning" style={{color: "white"}}>User Guide</Button>
            </OverlayTrigger>
          </div>
          <div>In House vs K-Street Lobbying</div>
        </div>
      )
      return (
        <ReportsVizContainer heading={lobbyingBarChartHeading}>
          <InHouseOutsourcedBarChart width={this.state.width} height={this.state.height} yearIssueAmountData={this.props.yearIssueAmountData} requestStatus={this.props.yearIssueAmountRequestStatus}/>
        </ReportsVizContainer>
      )
    }
    else if(this.state.tab === this.props.VIZ_KEYS.GOV_AGENCIES) {
      const govAgenciesBarChartHeading = (
        <div>
          <div style={{float: "right"}}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  <div>
                  Click on the radial buttons above the chart to toggle which elements are displayed. The checkbox allows the inclusion of House and Senate.
                  <br/><br/>
                  Hover over each of the bars to observe the precise figues and agencies that each bar represents.
                  <br/><br/>
                  Hover over elements on the X-axis to see the full name of each government agency being displayed.
                  <br/><br/>
                  Click and drag the slider button to change the amount of agencies that are being displayed.
                  </div>
                </Tooltip>
              }>
              <Button variant="warning" style={{color: "white"}}>User Guide</Button>
            </OverlayTrigger>
          </div>
          <div>Government Agencies Related to Lobbying Reports</div>
        </div>
      )
      return (
        <ReportsVizContainer heading={govAgenciesBarChartHeading}>
          <GovAgenciesContainer width={this.state.width} height={this.state.height} govAgenciesData={this.props.govAgenciesData}
          requestStatus={this.props.govAgenciesRequestStatus}/>
        </ReportsVizContainer>
      )
    }
    else if(this.state.tab === this.props.VIZ_KEYS.REVOLVING_DOOR) {
      const heading = (
        <div>
          <div style={{float: "right"}}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  <div>
                    This visualization depicts the experience that lobbyists had prior to entering lobbying.
                    <br/><br/>
                    Each outermost node represents a lobbyist mentioned in the reports returned by your query.
                  </div>
                </Tooltip>
              }>
              <Button variant="warning" style={{color: "white"}}>User Guide</Button>
            </OverlayTrigger>
          </div>
          <div>Revolving Door</div>
        </div>
      )

      return (
        <ReportsVizContainer heading={heading}>
          <RevolvingDoorContainer width={this.state.width} height={this.state.height} revolvingDoorData={this.props.revolvingDoorData} requestStatus = {this.props.revolvingDoorRequestStatus}/>
        </ReportsVizContainer>
      )
    }
  }

  getContent = () => {
    // Display visualizations
    if (this.props.viz === true) {
      return (
        <div className="box">
          <div ref={this.tabsRef}>
            <Tabs activeKey={this.state.tab} onSelect={eventKey => {
              const query = parseUrlQuery(window.location.search.substring(1)); //get the query in the url if any
              query.vizTab = eventKey; //set the vizTab query in the query object
              // this.props.history.push(this.props.location.pathname+"?"+objToQueryString(query)); //change the URL (which will change the tab in the history listener)

              this.props.getVizData(eventKey); //ask Query for the respective viz data
            }}>
              <Tab eventKey={this.props.VIZ_KEYS.WORD_CLOUD} title="Content Analysis"></Tab>
              <Tab eventKey={this.props.VIZ_KEYS.TIME_SERIES} title="Expenditure Over Time"></Tab>
              {/* <Tab eventKey={this.props.VIZ_KEYS.SANKEY} title="Finance Flow"></Tab> */}
              <Tab eventKey={this.props.VIZ_KEYS.IN_HOUSE_OUT_SOURCED} title="In House vs K-Street Lobbying"></Tab>
              <Tab eventKey={this.props.VIZ_KEYS.GOV_AGENCIES} title="Government Agencies"></Tab>
              <Tab eventKey={this.props.VIZ_KEYS.REVOLVING_DOOR} title="Revolving Door"></Tab>
            </Tabs>
            {this.getVizContent()}
          </div>
        </div>
      )
    }
    // Display single report details
    else {
      return (
        <Report
          report={this.props.reports[this.props.reportIdx]}
          issueIdx={this.props.issueIdx}
          setReportIssue={this.props.setReportIssue}
          showViz={this.props.showViz}
        />
      )
    }
  }

  render() {
    return (
      <div id="vizReport">
        {this.getContent()}
      </div>
    )
  }
}

VizReport.propTypes = {
  viz: PropTypes.bool.isRequired,
  reports: PropTypes.array.isRequired,
  reportIdx: PropTypes.number.isRequired,
  issueIdx: PropTypes.number.isRequired,
  wordCloudData: PropTypes.array.isRequired,
  wordCloudRequestStatus: PropTypes.string.isRequired,
  yearIssueAmountData: PropTypes.array.isRequired,
  yearIssueAmountRequestStatus: PropTypes.string.isRequired,
  govAgenciesRequestStatus: PropTypes.string.isRequired,
  // sankeyData: PropTypes.object.isRequired,

  getVizData: PropTypes.func.isRequired,
  showViz: PropTypes.func.isRequired,
  setReportIssue: PropTypes.func.isRequired,

  VIZ_KEYS: PropTypes.object.isRequired,
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *                                  Components for Report                            *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
class Report extends Component {
  render() {
    const {
      issueIdx,
      report,
      setReportIssue,
    } = this.props

    return (
      <div id="report">
        <Row id="report-header-container" className="box">
          <div id="report-header">
            <span>{capitalize(report.client.clientName)}</span>
            <span>{formatReportYearAndQuarter(report)}</span>
            <span>{report.amount==null ? <span className="grayBoldText">-----</span> : "$"+formatMoney(report.amount)}</span>
            <span>{capitalize(report.registrant.registrantName)}</span>
            <a href={report.reportUrl} target="_blank" rel="noopener noreferrer">
              <Button variant="primary" size="sm" title="View the Report!">
                <FontAwesomeIcon icon={faExternalLinkAlt}/>
              </Button>
            </a>
            <Button variant="secondary" size="sm" onClick={this.props.showViz}>
              <FontAwesomeIcon icon={faTimes}/>
            </Button>
          </div>
        </Row>

        <Row id="report-info">
          <Col className="box2" xs={4} id="issues-column">
            <h3 className="report-label">Issues</h3>
            <div>
              {report.issues.slice(0, report.issues.length).map((iss, i) =>
                <Issue
                  key={i}
                  issueCode={iss.issueCode}
                  isSelected={issueIdx === i}
                  onClick={e => setReportIssue(i)}
                />
              )}
            </div>
          </Col>

          <Col className="box2" xs={3}>
            <h3 className="report-label">Lobbyists</h3>
            <IssueTable
              issue={report.issues[issueIdx]}
              list="lobbyists"
            />
          </Col>

          <Col className="box2">
            <h3 className="report-label">Bills</h3>
            <IssueTable
              issue={report.issues[issueIdx]}
              list="bills"
            />

            <br/>

            <h3 className="report-label">Government Entities</h3>
            <IssueTable
              issue={report.issues[issueIdx]}
              list="govEntities"
            />
          </Col>
        </Row>
      </div>
  )}
}

function Issue(props) {
  return (
    <div className={"issue" + (props.isSelected ? " selected" : "")} onClick={props.onClick}>
      {mapIssueCode(props.issueCode)}
    </div>
  );
}

function IssueTable(props) {
  if (props.issue) {
    if (props.list === "lobbyists") {
      return (
        props.issue.lobbyistsIssuesAfter2007SByReportUuidAndIssueOrdi.map((lob, i) =>
        <div key={i} className="report-item"> {capitalize(lob.lobbyist.lobbyistConsolidatedName)}</div>
      ));
    }
    else if(props.list==="bills" && props.issue.issueBillsByReportUuidAndIssueOrdi.length>0) {
      return (
        props.issue.issueBillsByReportUuidAndIssueOrdi.map((bill, i) =>
        <div key={i} className="report-item">
          <span>{bill.billByCongressNumberAndBillId.congressNumber} </span>
          <span>{capitalize(bill.billByCongressNumberAndBillId.billId.billChamber)} </span>
          <span>{bill.billByCongressNumberAndBillId.billId.billNumber} </span>
          <span>{bill.billByCongressNumberAndBillId.billTitlesByCongressNumberAndBillId[0].billTitle} </span>
        </div>
      ));
    }
    else if (props.list === "govEntities") {
      return (
        props.issue.issuesGovEntitiesAfter2007SByReportUuidAndIssueOrdi.map((gov, i) =>
        <div key={i} className="report-item">{capitalize(gov.govEntity)}</div>
      ));
    }
  }

  return <div className="report-item grayBoldText">No data</div>;
}
