import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LegendItem = styled.div`
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
`;

const LegendItemColorClicked = styled.div`
    display: inline-block;
    border: 3px solid ${(props) => props.color};
    border-radius: 50%;
    margin-right: 5px;
    width: 16px;
    height: 16px;
    vertical-align: middle;
`;

const LegendItemColor = styled.div`
    display: inline-block;
    border-radius: 50%;
    margin-right: 5px;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background: ${(props) => props.color};
`;

const LegendItemText = styled.div`
    display: inline;
    font-size: 12px;
`;

export default class LegendItemComponent extends Component {

  render(){
    const { onClickLegendItem, color, text, toggle } = this.props;

      if(toggle) {
        //visual change of the cicle to indicate which bars are chosen to display
        return(
          <LegendItem onClick={e => onClickLegendItem(color)}>
              <LegendItemColor color={color} />
              <LegendItemText>{text}</LegendItemText>
          </LegendItem>
        )
      } else {
        return(
          <LegendItem onClick={e => onClickLegendItem(color)}>
              <LegendItemColorClicked color={color} />
              <LegendItemText>{text}</LegendItemText>
          </LegendItem>
        )
    }
  }
}

LegendItemComponent.propTypes = {
    color: PropTypes.string,
    onClickLegendItem: PropTypes.func,
    text: PropTypes.string,
    toggle: PropTypes.bool,
};
