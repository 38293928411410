import gql from 'graphql-tag';

/**
 * given an array of report ids, return the query to get the year issue amount data
 * @param  {Array} reportIds  array of report id strings
 * @return {Graphql Query}
 */
const getYearIssueAmountQuery = (reportIds) => gql`
query getYearIssueAmount {
  vizGetYearIssueAmount(
    jsonReportUuids: ${JSON.stringify(reportIds)}
  )
}
`

export default getYearIssueAmountQuery
