import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LegendItem from './LegendItem';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Legend = styled.div`
    margin-top: 25px;
    margin-left: ${(props) => `${props.left}px`};
`;

const DEFAULT_COLOR = '#37474F';

export default class Legends extends Component {
    static propTypes = {
        left: PropTypes.number,
        legendKeys: PropTypes.array,
        customLegendElement: PropTypes.element
    };

    render() {
        const { legendKeys, left } = this.props;
        //see inHouseOutsourcedBarChart for example of legendKeys data format
        return (
          <Row>
            <Col sm={12} md={9}>
              <Legend left={left}>
                {this.props.showLegend !== false ? legendKeys.map(item =>
                  <LegendItem
                    key={(item && item.color) || DEFAULT_COLOR}
                    onClickLegendItem={this.props.onClickLegendItem}
                    color={(item && item.color) || DEFAULT_COLOR}
                    text={item && item.text}
                    toggle={item && item.toggle}
                  />
                ) : null}

                {this.props.customLegendElement}
              </Legend>
            </Col>
          </Row>
        );
    }
}
