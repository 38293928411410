import gql from 'graphql-tag';

const AUTO_SUGGEST_SIG_TICKER = input => gql`
{
  topTicsCodes(_approximateTicCode: "${input}") {
    candidateTicCode
    confidence
  }
}
`

export default AUTO_SUGGEST_SIG_TICKER
