import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  event as d3Event,
  linkRadial as d3LinkRadial,
  scaleOrdinal as d3ScaleOrdinal,
  scaleSqrt as d3ScaleSqrt,
  select as d3Select,
  selectAll as d3SelectAll,
  tree as d3Tree,
  zoom as d3Zoom,
} from 'd3'

import processRevolvingDoorData from "./processRevolvingDoorData/processRevolvingDoorData"

import "./revolvingDoorContainer.css"

const HEIGHT = 600;

export default class RevolvingDoorContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: null,
      boundingBoxUpdated: false,
    };
  }

  componentDidUpdate(prevProps){
    // If new data came in
    if (this.props.revolvingDoorData !== prevProps.revolvingDoorData){
      if (this.props.revolvingDoorData.length > 0) this.updateData();
      else this.setState({
        data: null
      });
    }

    // Resizing
    else if(this.state.data) this.updateBoundingBox();
  }

  componentDidMount(){
    if (this.props.revolvingDoorData.length > 0) this.updateData();
  }

  updateData = () => {
    this.setState({
      data: processRevolvingDoorData(this.props.revolvingDoorData),
      boundingBoxUpdated: false,
    }, ()=>{
      this.updateBoundingBox();
      this.addHandlers();
    });
  }

  updateBoundingBoxHelper = () => { // Handle Resizing
    d3Select("#radial-tree").select("svg").attr("width", this.props.width).attr("height", HEIGHT);
  }

  updateBoundingBox = () => { // We need to update the bounding box twice, once to display the graph, then next to choose the appropriate bounding box. However, the very first time we draw the graph, we draw the bounding box once.
    this.updateBoundingBoxHelper();
    if(this.state.boundingBoxUpdated) this.updateBoundingBoxHelper();
    else this.setState({
      boundingBoxUpdated: true
    })
  }

  addHandlers = () => { // We need to update the bounding box twice, once to display the graph, then next to choose the appropriate bounding box. However, the very first time we draw the graph, we draw the bounding box once.
    this.addHandlersHelper();
    if(this.state.handlersAdded) this.addHandlersHelper();
    else this.setState({
      handlersAdded: true
    })
  }

  addHandlersHelper = () => { // Add zoom, hover, and other handlers only once for a given data entry
    const svg = d3Select("#radial-tree").select("svg"),
          g = svg.select("g"),
          breadcrumbs = d3Select("#breadcrumbs"),
          nodes = svg.selectAll(".node"),
          lines = svg.selectAll(".link"),
          width = this.props.width,
          height = HEIGHT;

    const bbox = svg.node().getBBox();

    const descendants = this.state.data.descendants().map((d, i) => {d.data.index = i; return d}),
        links = this.state.data.links().map((d, i) => {d.index = i; return d}),
        lobbyistToNodes = {}; // Mapping of lobbyist names to an array of nodes lobbyist represents

    // Pregenerate data for hover animations to save time.
    descendants.forEach(function(d) {
      if(d.children == null){
        if(!(d.data.name in lobbyistToNodes)) lobbyistToNodes[d.data.name] = {"clade": [], "numLobbyists": 0};
        lobbyistToNodes[d.data.name].clade = lobbyistToNodes[d.data.name].clade.concat(d.ancestors());
        lobbyistToNodes[d.data.name].numLobbyists += 1;
      }
    })

    // Add text background
    d3SelectAll(".node").selectAll("text")
      .each(function(d) {
        const textBox = this.getBBox(),
              textAnchor = this.getAttribute("text-anchor"),
              x = this.getAttribute("x"),
              width = textBox.width + 20,
              height = textBox.height;

        d3Select(this.parentNode).select("rect")
          .attr("width", width)
          .attr("height", height)
          .attr("y", -1 * height / 2)
          .attr("x", textAnchor === "start" ? x : (textAnchor === "end" ? x - width : x - width / 2));
      });

    let total = descendants[0].numDescendants,
        committee = 0,
        govAgencies = 0,
        legislators = 0;

    for(let i = 1; i < Math.min(4, descendants.length); i++){
      if(descendants[i].data.name === "Worked in Committee") committee = descendants[i].numDescendants;
      if(descendants[i].data.name === "Worked for a Government Entity") govAgencies = descendants[i].numDescendants;
      if(descendants[i].data.name === "Worked for a Legislator") legislators = descendants[i].numDescendants;
    }

    // Add center text
    d3Select("#node-0").select("p").select("span").html(
      `Of the <strong>${total}</strong> lobbyist${total === 1 ? "" : "s"}, <strong>${committee}</strong> worked in committee, <strong>${govAgencies}</strong> in gov. agencies, and <strong>${legislators}</strong> for legislators before lobbying.`
    );

    // Hover animation
    function hoverOn(node){
      let clade, paths;

      // Hovering over lobbyist
      if(node.children === null) {
        // Center node text
        if(lobbyistToNodes[node.data.name].numLobbyists === 1) d3Select("#node-0").select("p").select("span").html(`<strong>${node.data.name}</strong> worked for <strong>${node.parent.data.name}</strong> before becoming a lobbyist.`);
        else d3Select("#node-0").select("p").select("span").html(`<strong>${node.data.name}</strong> had <strong>${lobbyistToNodes[node.data.name].numLobbyists}</strong> prior experiences before becoming a lobbyist.`);

        // Highlight all paths to lobbyists with the same name
        clade = lobbyistToNodes[node.data.name].clade;
        paths = links.filter(d => clade.indexOf(d.target) !== -1); // All paths connecting this

        // Only add breadcrumbs on a lobbyist
        let label = breadcrumbs.style("visibility", "visible").select("text").text(node.data.name + " (Lobbyist):"); // Add lobbyist label
        breadcrumbs.select("g").attr("transform", "translate(" + (label.node().getBBox().width + 10) + ", 0)"); // Adjust breadcrumbs so they don't intersect label

        let leftOffsets = [0, 0, 0, 0];
        let trail = node.ancestors().sort((a, b) => a.depth - b.depth); // Breadcrumbs, in order of depth
        trail = trail.slice(1, trail.length - 1);

        d3Select("#breadcrumbs").select("g").selectAll("g")
          .data(trail)
          .each(function(d, i){
            let group = d3Select(this);

            let text = group.select("text").text(d.data.name).attr("x", i === 0 ? 10 : 15).attr("fill", d.data.name === "Republican" || d.data.name === "Democrat" ? "white" : "black"),
                textWidth = text.node().getBBox().width,
                leftOffset = (i === 0 ? 0 : leftOffsets[i - 1] + d3Select(".crumb-" + (i - 1)).node().getBBox().width + 3);

            leftOffsets[i] = leftOffset;

            group.select("polygon")
              .attr("points", "0,0 " + (textWidth + 20) + ",0 " + (textWidth + 30) + ",15 " + (textWidth + 20) + ",30 0,30" + (i === 0 ? "" : " 10,15")) // Scale polygon width to width of text
              .attr("fill", d.data.name === "Republican" ? "#cf000f" : (d.data.name === "Democrat" ? "#1f3a93" : "#eee"))

            group.attr("transform", "translate(" + leftOffset + ",0)").style("visibility", "visible");
          });
      }
      else if(node.depth !== 0){
        // Center node text
        let text = `There ${node.numDescendants === 1 ? "is" : "are"} <strong>${node.numDescendants}</strong> lobbyist${node.numDescendants === 1 ? "" : "s"} `;

        if(node.depth === 1) text += `who ${node.data.name.toLowerCase()}`;
        else if(["Republican", "Democrat", "Independent"].indexOf(node.data.name) !== -1) text += `who worked for ${node.data.name} legislators`;
        else text += `who worked for ${node.data.name}`;

        d3Select("#node-0").select("p").select("span").html(text);

        // Paths to higlight
        clade = [node].concat(node.descendants()); // All descendants of the current node
        paths = links.filter(d => clade.indexOf(d.source) !== -1); // All paths connecting this family
      }
      else return;

      lines.style("opacity", 0.3);
      nodes.style("opacity", 0.3);

      // Color all nodes & links related to the node being hovered
      clade.forEach(function(d){
        d3Select("#node-" + d.data.index).style("opacity", 1)
      });
      paths.forEach(function(d){
        d3Select("#link-" + d.index).style("opacity", 1).style("stroke-width", 15);
      })
      d3Select("#node-0").style("opacity", 1);
    }

    function hoverOff(){
      nodes.style("opacity", 1);
      d3SelectAll(".link-visible").style("stroke-width", 3).style("opacity", 1);
      breadcrumbs.style("visibility", "hidden");
      breadcrumbs.select("g").selectAll("g").style("visibility", "hidden");

      d3Select("#node-0").select("p").select("span").html(`Of the <strong>${total}</strong> lobbyist${total === 1 ? "" : "s"}, <strong>${committee}</strong> worked in committee, <strong>${govAgencies}</strong> in gov. agencies, and <strong>${legislators}</strong> for legislators before lobbying.`);
    }

    nodes.on("mouseover", function(d, i) {
      hoverOn(descendants[i])
    }).on("mouseout", hoverOff);

    lines.on("mouseover", function(d, i) {
      if(links[this.getAttribute("index")]) hoverOn(links[this.getAttribute("index")].target)
    }).on("mouseout", hoverOff);

    // Pan and zoom handler
    let zoom = d3Zoom()
      // .translateExtent([[-bbox.width, -bbox.height], [bbox.width, bbox.height]])
      .scaleExtent([0, 2])
      .on("zoom", function () {
         g.attr("transform", d3Event.transform);
         g.attr("scale", d3Event.transform.k);
      })

    const ZOOM_FACTOR = 1.25,
          zoomHandler = (factor) => {
            svg.transition().duration(150).call(zoom.scaleBy, factor);
          }

    d3Select("#zoom-in").on("click", () => {zoomHandler(ZOOM_FACTOR)});
    d3Select("#zoom-out").on("click", () => {zoomHandler(1 / ZOOM_FACTOR)});

    d3Select("#reset-frame").on("click", function(){
      zoom.translateTo(svg, 0, 0);
      zoom.scaleTo(svg, Math.min(height / bbox.height, width / bbox.width));
    })

    svg.call(zoom).on("wheel.zoom", null).on("dblclick.zoom", null);

    // Click on node to zoom in
    nodes.on("click", (d, i) => {
      let node = descendants[i];

      let minX = 0, minY = 0, maxX = 0, maxY = 0;

      // Get bounding box
      node.descendants().forEach(function(d){
        if(d.children == null) {
          let x = Math.cos(d.x - Math.PI / 2) * (d.y + d.data.name.length * 30),
              y = Math.sin(d.x - Math.PI / 2) * (d.y + d.data.name.length * 30);

          if(x > maxX) maxX = x;
          if(y > maxY) maxY = y;
          if(x < minX) minX = x;
          if(y < minY) minY = y;
        }
      });

      // Ensure text of center node is visible
      maxX = Math.max(maxX, 250);
      maxY = Math.max(maxY, 100);
      minX = Math.min(minX, -250);
      minY = Math.min(minY, -60);

      zoom.translateTo(svg, (maxX + minX) / 2, (maxY + minY) / 2);
      zoom.scaleTo(svg, Math.min(height / (maxY - minY), this.props.width / (maxX - minX)));
    });

    // Center the graph
    zoom.translateTo(svg, 0, 0);
    zoom.scaleTo(svg, Math.min(height / bbox.height, width / bbox.width));
  }

  render() {
    let graphScale = 1.5,
        radius = graphScale * this.props.width / 2;

    let tree = d3Tree()
      .size([2 * Math.PI, radius])
      .separation((a, b) => {
        if (a.parent === b.parent){
          if (a.depth === 2 && a.height === 1) return 6 / a.depth; // Add more spacing to multi-line nodes
          else return 3 / a.depth;
        }
        else return 4 / a.depth;
      });

    let root, links, descendants, nodeScale,
        depthScale = d3ScaleOrdinal().domain([0, 1, 2, 3, 4]).range([graphScale * 56, graphScale * 48, graphScale * 36, graphScale * 28, graphScale * 24]); // Scale font size to depth

    if(this.state.data){
      root = tree(this.state.data);

      links = root.links();
      descendants = root.descendants().map(function(d){
        d.y = (d.depth * 600);
        if(d.depth === 2 && d.height === 1) d.y += 500; // Scale committee and gov agency labels
        d.y = d.children ? d.y : d.parent.y + 300; // Use shorter links for the last level

        let theta = d.x * 180 / Math.PI - 90;

        if(d.depth === 1 && ((theta >= 150 && theta <= 220) || (theta >= -40 && theta <= 30))) d.y += 300;

        d.fontSize = depthScale(d.depth);
        let leafs = [];

        d.descendants().forEach(function(e){
          if(e.children == null && leafs.indexOf(e.data.name) === -1) leafs.push(e.data.name);
        });

        d.numDescendants = leafs.length;

        return d;
      });

      nodeScale = d3ScaleSqrt().domain([1, descendants[0].numDescendants]).range([2.5, 60]);
    }

    let link = d3LinkRadial()
      .angle(function(d) { return d.x; })
      .radius(function(d) { return d.y; });

    let getLabelPosition = function(d){
      let position = {
        dy: "0.31em",
        x: ((d.x < Math.PI) === !d.children ? 1 : -1) * (nodeScale(d.numDescendants) + 5),
        textAnchor: (d.x < Math.PI) === !d.children ? "start" : "end",
        transform: `
          rotate(${d.x * 180 / Math.PI - 90})
          translate(${d.y},0)
          rotate(${d.x >= Math.PI ? 180 : 0})
        `
      }

      // Center and do not rotate text for the first two levels
      if(d.depth === 0) {
        position.transform = `translate(-500,-150)`;
        position.textAnchor = "middle";
        position.x = 0;
      }
      else if(d.depth === 1 || (d.depth === 2 && d.parent.data.name === "Worked for a Legislator")){
        position.transform = `
          rotate(${d.x * 180 / Math.PI - 90})
          translate(${d.y},0)
          rotate(${180 - d.x * 180 / Math.PI - 90})
          translate(0, ${nodeScale(d.numDescendants) + 20})
        `;
        position.textAnchor = "middle";
      }
      // Committee or gov agency need to be foreign objects, to account for line breaks
      else if(d.depth === 2 && d.height === 1){
        position.transform = `
          rotate(${d.x * 180 / Math.PI - 90})
          translate(${d.y - (position.textAnchor === "start" ? 0 : 500)},${(position.textAnchor === "start" ? 1 : -1) * 80})
          rotate(${d.x >= Math.PI ? 180 : 0})
        `
      }

      position.transform += `scale(1.0,1.0)`

      return position;
    };

    return (
      <React.Fragment>
        {this.state.data ?
          <React.Fragment>
            <svg id = "breadcrumbs" width = {this.props.width} height = {50} style = {{display: "none"}}>
              <text x="0" y="15" dy="0.35em"></text>
              <g>
                {[0, 1, 2].map(function(d, i){
                  return (<g key = {"crumb-" + i} className = {"crumb-" + i}>
                    <polygon></polygon>
                    <text x="0" y="15" dy="0.35em"></text>
                  </g>)
                })}
              </g>
            </svg>
          </React.Fragment>
        : null}
        <div id = "radial-tree" style = {{position: "relative", cursor: "all-scroll"}}>
          <React.Fragment>
            {this.state.data ?
              <React.Fragment>
                <div id = "zoom-control" style = {{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  background: "white",
                  textAlign: "center",
                  border: "1px solid #dcdcdc",
                  borderRadius: 5,
                  fontSize: 18,
                  cursor: "pointer"
                }}>
                  <div id = "zoom-in" style = {{padding: "5px 10px"}}>+</div>
                  <div id = "zoom-out" style = {{padding: "5px 10px"}}>-</div>
                </div>
                <div id = "reset-frame" style = {{
                  position: "absolute",
                  bottom: "15px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  textAlign: "center",
                  border: "1px solid #dcdcdc",
                  background: "white",
                  borderRadius: 5,
                  fontSize: 18,
                  cursor: "pointer",
                }}>
                  <div style = {{padding: "5px 10px"}}>Recenter Graph</div>
                </div>
              </React.Fragment>
            : null}
            <svg style = {{
              display: this.state.data ? "block" : "none",
              margin: "0 auto",
            }}>
              <g>
              {this.state.data ?
                (
                  <React.Fragment>
                    {/*Links*/}
                    <g
                      fill = "none"
                    >
                      {
                        links.map(function(d, i){
                          return (
                            <React.Fragment key = {"link-" + i}>
                              <path
                                d = {link(d)}
                                id = {"link-" + i}
                                className = "link link-visible"
                                style = {{
                                  transition: "0.1s",
                                  strokeWidth: "3",
                                  strokeOpacity: "0.4",
                                  stroke: d.source.data.name === "Republican" ? "#cf000f" : (d.source.data.name === "Democrat" ? "#1f3a93" : "#000")
                                }}
                              >
                              </path>
                              {/*Larger, invisible path to handle hover*/}
                              <path
                                d = {link(d)}
                                index = {i}
                                className = "link"
                                style = {{
                                  strokeWidth: "20",
                                  strokeOpacity: "0",
                                }}
                              >
                              </path>
                            </React.Fragment>
                          )
                        })
                      }
                    </g>

                    {/*Nodes & Labels*/}
                    {
                      descendants.map(function(d, i){
                        return (
                          <g
                            className = "node"
                            index = {i}
                            id = {"node-" + i}
                            key = {"node-" + i}
                            >
                            {/*Nodes*/}
                            {i === 0 ? null : (
                              <circle
                                transform = {`rotate(${d.x * 180 / Math.PI - 90}) translate(${d.y},0)`}
                                r = {nodeScale(d.numDescendants)}
                                style = {{
                                  transition: "0.1s",
                                  fill: d.data.name === "Republican" ? "#cf000f" : (d.data.name === "Democrat" ? "#1f3a93" : "#ccc"),
                                  stroke: d.data.name === "Republican" ? "#98000b" : (d.data.name === "Democrat" ? "#142662" : "#8c8c8c"),
                                  strokeWidth: nodeScale(d.numDescendants) / 10
                                }}
                              >
                              </circle>
                            )}

                            {/*Labels*/}
                            {d.depth === 2 && d.height === 1 ? ( /* Multiline labels for committees and gov agencies */
                              <foreignObject
                                {...getLabelPosition(d)}
                                width="500" height="200"
                                style = {{
                                  userSelect: "none",
                                  fontSize: d.fontSize,
                                  textAlign: (d.x < Math.PI) === !d.children ? "left" : "right",
                                  transition: "0.1s"
                                }}
                                className = "text"
                              >
                                <div style = {{
                                  height: "100%",
                                  position: "relative"
                                }}>
                                  <p style = {{
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    position: "absolute",
                                    lineHeight: d.fontSize + "px",
                                    right: (d.x < Math.PI) === !d.children ? "unset" : 0,
                                    left: (d.x < Math.PI) === !d.children ? 0 : "unset",
                                  }}>
                                    <span style = {{background: "rgba(255, 255, 255, 0.7)"}}>{d.data.name}</span>
                                  </p>
                                </div>
                              </foreignObject>
                            ) : (d.depth === 0 ? ( /*Multiline label for center node */
                              <foreignObject
                                {...getLabelPosition(d)}
                                width="1000" height="350"
                                style = {{
                                  userSelect: "none",
                                  fontSize: d.fontSize,
                                  textAlign: "center",
                                  transition: "0.1s"
                                }}
                                className = "text"
                              >
                                <div style = {{
                                  height: "100%",
                                  position: "relative"
                                }}>
                                  <p style = {{
                                    position: "absolute",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    lineHeight: d.fontSize + "px",
                                    width: "100%"
                                  }}>
                                    <span style = {{background: "rgba(255, 255, 255, 0.7)"}}>{d.data.name}</span>
                                  </p>
                                </div>
                              </foreignObject>
                            ) : (
                              <React.Fragment> {/* Labels for everything else */}
                                <rect
                                  {...getLabelPosition(d)}
                                  fill = {d.children ? (d.height === 1 ? "rgba(255, 255, 255, 0.7)" : "rgba(255, 255, 255, 0.5)") : "rgba(255, 255, 255, 0)"}
                                >
                                </rect>
                                <text
                                  {...getLabelPosition(d)}
                                  style = {{
                                    userSelect: "none",
                                    fontSize: d.fontSize,
                                    transition: "0.1s"
                                  }}
                                  className = "text"
                                >
                                  {d.data.name}
                                </text>
                              </React.Fragment>
                            )
                          )}
                          </g>
                        )
                      })
                    }
                  </React.Fragment>
                )
              : null}
              </g>
            </svg>
            { this.state.data ? null : (this.props.requestStatus === "loading" ? <div>Loading...</div> : (this.props.requestStatus === "empty" ? <div>This query does not have any revolving door data.</div> : <div>Please search for reports using the form to get data for this visualization</div>))}
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}

RevolvingDoorContainer.propTypes = {
  width: PropTypes.number.isRequired,
  revolvingDoorData: PropTypes.array.isRequired,
}
