/**
 * [given two objects, this function tries to merge them (similar concept to git branch merging). Objects values will be recurisvely merged while array values will be concatenated]
 * @param  {[Object]} mainObj      [the main object]
 * @param  {[Object]} mergingObj      [the merging object]
 * @param  {[Boolean]} overwrite [whether or not mergingObj should overwrite mainObj fields if they conflict]
 * @param  {[String]} fullField [the full field in the objects using dot notatino]
 * @return {[Object]}           [returns the final object]
 */
export default function mergeObjects(mainObj={}, mergingObj={}, overwrite=false, verbose=true, fullField="") {
  let finalObj = JSON.parse(JSON.stringify(mainObj));

  for(let field in mergingObj) {
    const newFullField = fullField.length>0 ? fullField+"."+field : field;

    if(typeof finalObj[field] === "undefined") {
      finalObj[field] = mergingObj[field];
    }
    else if(typeof finalObj[field] === typeof mergingObj[field]) {
      if(Array.isArray(finalObj[field])) { //if this is an array, concat the values
        finalObj[field] = JSON.parse(JSON.stringify( finalObj[field].concat(mergingObj[field]) ));
      }
      else if(typeof finalObj[field] === "object") { //if the value is an object

        finalObj[field] = mergeObjects(finalObj[field], mergingObj[field], overwrite, verbose, newFullField); //recursively merge these objects
      }
      else if(overwrite) { //if overwrite is set to true, the merging object will overwrite the main object
        finalObj[field] = mergingObj[field];
        if(verbose) console.warn("MainObj and mergingObj had different values at field "+newFullField+". Since overwrite was set to true, mergingObj's value is kept");
      }
      else {
        if(verbose) console.warn("MainObj and mergingObj had different values at field "+newFullField+". Since overwrite was set to false, mainObj's value is kept");
      }
    }
    else {
      delete finalObj[field]
      if(verbose) console.warn("The types of mainObj and mergingObj did not agree at field "+newFullField+", so the field will be ignored");
    }
  }

  return finalObj;
}
