import gql from 'graphql-tag';

//query taking in a filter variable based on this tutorial: https://www.howtographql.com/react-apollo/7-filtering-searching-the-list-of-links/
const REPORTS_QUERY = gql`
query FormQuery($filter: ReportFilter!) {
  reports(
    first: 5000
    orderBy: DATE_RECEIVED_DESC
    filter: $filter
  ) {
    reportActivity
    reportQuarterCode
    amount
    isAmendment
    isNoActivity
    registrantUuid
    clientUuid
    reportUrl
    isClientSelfFiler
    dateReceived
    reportUuid
    reportYear
    isClientStateOrLocalGov
    issues {
      issuesGovEntitiesAfter2007SByReportUuidAndIssueOrdi {
        govEntity
      }
      issueCode
      issueOrdi
      lobbyistsIssuesAfter2007SByReportUuidAndIssueOrdi {
        lobbyist {
          lobbyistConsolidatedName
          lobbyistUuid
        }
      }
      issueBillsByReportUuidAndIssueOrdi {
        billByCongressNumberAndBillId {
          billId {
            billChamber
            billNumber
            billResolutionType
          }
          congressNumber
          billTitlesByCongressNumberAndBillId(first: 1) {
            billTitle
          }
          billUrl
        }
      }
    }
    registrant {
      registrantName
    }
    client {
      clientName
      clientUuid
    }
  }
}
`;

export default REPORTS_QUERY
