import gql from 'graphql-tag';

/**
 * given a set of lobbyist uuids, return the query to get the revolving door viz data
 * @param  {Set} lobbyist_uuids set of lobbyist uuid strings
 * @return {Graphql Query}
 */
export default function getLobbyistRevolvingDoorQuery(lobbyist_uuids) {
  return (
    gql`
    query getLobbyistsRevDoorHistory {
      vizGetLobbyistsRevDoorHistory(lobbyistsUuid: ${JSON.stringify(Array.from(lobbyist_uuids))}) {
        lobbyistUuid
        revolvingDoorComm {
          committeeId {
            committeeChamber
            committeeCode
            subcommitteeNumber
          }
          committeeMostRecentName
        }
        revolvingDoorGovEntity {
          govEntity
        }
        revolvingDoorLegislators {
          legislatorLastName
          legislatorFirstName
          legislatorsTerms {
            termParty
            termCongressChamber
          }
        }
      }
    }
    `
  )
}
