import React from 'react';
import PropTypes from 'prop-types';

import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import {
  useAsyncDebounce,
} from 'react-table'
import "./globalFilter.scss"

export default function GlobalFilter({
  getPlaceholderText,
  globalFilter,
  preGlobalFilteredRowsCount,
  setGlobalFilter,
}) {
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <div className="globalFilter">
      <InputGroup>
        <FormControl
          type="text"
          value={value || ""}
          onChange={e => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
          placeholder={getPlaceholderText(preGlobalFilteredRowsCount)}
        />
      </InputGroup>
    </div>
  )
}

GlobalFilter.propTypes = {
  getPlaceholderText: PropTypes.func.isRequired,
  globalFilter: PropTypes.object,
  preGlobalFilteredRowsCount: PropTypes.number.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
}
