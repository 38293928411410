import gql from 'graphql-tag';

const WORD_CLOUD_QUERY = gql`
query FormQuery($filter: ReportFilter!) {
  reports(
    first: 100
    orderBy: DATE_RECEIVED_DESC
    filter: $filter
  ) {
    issues {
      lobbyingIssueNgramByReportUuidAndIssueOrdi {
        ngramJ
      }
    }
  }
}
`

export default WORD_CLOUD_QUERY
