import gql from 'graphql-tag';

const AUTO_SUGGEST_SIG_GVKEY = input => gql`
{
  topGvkeys(_approximateGvkey: "${input}"){
    candidateGvkeyCode
    companyCommonName
    confidence
  }
}
`

export default AUTO_SUGGEST_SIG_GVKEY
