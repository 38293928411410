import comparator from 'object-comparator';

type NgramjType = {
  c: number,
  ng_d: number,
  ngram: string[]
}

type IssueType = {
  lobbyingIssueNgramByReportUuidAndIssueOrdi: {
    ngramJ: NgramjType[]
  }
}

type GraphqlWordCloudReportsType = {
  issues: IssueType[],
}


type GramType = {
  key: string,
  value: number,
}

type WordCloudDataType = GramType[][]

export default function processWordCloudData(
  reports: GraphqlWordCloudReportsType[],
  maxValues: number = 100,
):WordCloudDataType {
  const wordCloudData:WordCloudDataType = [];

  for(let reportIndex=0; reportIndex<reports.length; ++reportIndex) { //loop through each report
    for(let reportIssueIndex=0; reportIssueIndex<reports[reportIndex].issues.length; ++reportIssueIndex) { //loop through each issuei n this report
      if(reports[reportIndex].issues[reportIssueIndex].lobbyingIssueNgramByReportUuidAndIssueOrdi) { //if this is ngram data
        const ngramJ = reports[reportIndex].issues[reportIssueIndex].lobbyingIssueNgramByReportUuidAndIssueOrdi.ngramJ; //get the array of words for this issue

        for(let ngramJIndex=0; ngramJIndex<ngramJ.length; ++ngramJIndex) { //loop through all the words
          //while this gram doesn't exist in the data yet, push empty arrays until we get to the right gram
          const gram = ngramJ[ngramJIndex].ng_d;
          while(wordCloudData.length < gram) { //while we don't have enough grams yet
            wordCloudData.push([]); //push an empty array into our data
          }

          const key = ngramJ[ngramJIndex].ngram.map(n => n.trim()).join(" "); //join the array of strings to get the word(s)
          if(!HARD_CODED_IGNORE_WORDS.includes(key.toLowerCase())) { //if this key is not on the hard coded ignore list
            //loop through all the words to see if this word combo exists yet
            let exists = false;
            for(let wordIndex=0; wordIndex<wordCloudData[gram-1].length; ++wordIndex) { //TODO this could possibly be optimized by binary searching
              if(wordCloudData[gram-1][wordIndex].key === key) { //if this key already exists
                wordCloudData[gram-1][wordIndex].value += ngramJ[ngramJIndex].c; //increment the value by the count
                exists = true;
                break;
              }
            }

            //if the key doesn't exist yet, make a new key object, TODO this could be possibly optimized by inserting in order
            if(exists === false) {
              wordCloudData[gram-1].push({
                key: key,
                value: ngramJ[ngramJIndex].c
              })
            }
          }
        }
      }
    }
  }

  //final data processing
  for(let gramIndex=0; gramIndex<wordCloudData.length; ++gramIndex) { //loop through each gram array
    wordCloudData[gramIndex].sort(comparator( [{field:"value",direction:-1}] )); //sort the array in descending order
    wordCloudData[gramIndex] = wordCloudData[gramIndex].slice(0,maxValues); //keep only the first X values
  }

  return wordCloudData
}

const HARD_CODED_IGNORE_WORDS = [
  'issue', 'related', 'issue related'
]
