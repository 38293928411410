/**
 * given an object, returns true if the object is empty, ex {}
 * returns false if the object has fields (is not empty) OR if the input variable is not of type object
 * how to test if a variable is an empty object: https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
 * @param  {Object}  obj input object
 * @return {Boolean}     true if the object is empty
 */
export default function isEmptyObject(obj) {
  if(
    obj
    && Object.entries(obj).length === 0
    && obj.constructor === Object
  ) {
    return true;
  }
  return false;
}
