import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSortAmountDown,
  faSortAmountDownAlt,
} from '@fortawesome/free-solid-svg-icons'

import "./sortIcons.scss"

export default class SortIcons extends React.Component {
  getIcon = () => {
    const {
      isSortedDesc,
      sortIconType,
    } = this.props

    if(sortIconType==="alphabetical" && isSortedDesc) {
      return faSortAlphaDownAlt
    }
    else if(sortIconType==="alphabetical") {
      return faSortAlphaDown
    }
    else if(isSortedDesc) {
      return faSortAmountDown
    }
    else {
      return faSortAmountDownAlt
    }
  }

  render() {
    return (
      <span className="sortIcons">
        {this.props.isSorted ? <FontAwesomeIcon icon={this.getIcon()}/> : ''}
      </span>
    )
  }
}

SortIcons.propTypes = {
  isSorted: PropTypes.bool.isRequired,
  isSortedDesc: PropTypes.bool.isRequired,
  sortIconType: PropTypes.string,
}
