type PointType = {
  gov_entity: string,
  n_issues: number,
  tot_per_year_per_issue_per_inout: number,
}

type GovAgenciesInputDataType = {
  aggregate: PointType[],
  keys: string[],
  // points: {
  //   [year: string]: PointType[]
  // }
}

type YAxisType =
| 0 //number of issues
| 1 //money spent




type GovAgenciesOutputPointType = {
  fullName: string,
  name: string,
  values: [number],
}

export default function processGovAgenciesData(
  govAgenciesData:GovAgenciesInputDataType,
  showHouseAndSenate: boolean,
  yAxis:YAxisType,
):GovAgenciesOutputPointType[] {
  if(govAgenciesData.keys.length > 0){
    return govAgenciesData.aggregate.filter(d => (
      //Filter out house and senate if selected
      showHouseAndSenate //if we want to show house and senate, allow every data point to pass
      || ( //else this data point must not be the house or the senate
        d.gov_entity !== "HOUSE OF REPRESENTATIVES" && d.gov_entity !== "SENATE"
      )
    )).map(d => {
      const name = d.gov_entity.split(" ")
      let initials = ""

      for (let i=0; i<name.length; i++){
        initials += name[i][0]
      }

      return {
        name: initials,
        fullName: d.gov_entity,
        // Select which y axis (num issues or money) to display
        values: [yAxis === 0 ? d.n_issues : Math.round(d.tot_per_year_per_issue_per_inout)] as [number]
      }
    }).sort( //Sort results
      (a, b) => b.values[0] - a.values[0]
    );
  }

  return []
}
