import {
  ascending as d3Ascending,
  hierarchy as d3Hierarchy,
} from 'd3'

type CommType = {
  committeeId: {
    committeeChamber: string,
    committeeCode: string,
    subcommitteeNumber: number | null,
  },
  committeeMostRecentName: string,
}

type GovEntityType = {
  govEntity: string,
}

type TermType = {
  termParty: string,
  termCongressChamber: string,
}
type LegislatorType = {
  legislatorFirstName: string,
  legislatorLastName: string,
  legislatorsTerms: TermType[],
}


type LobbyistDataType = {
  lobbyistUuid: string,
  name: string,
  revolvingDoorComm: CommType[] | null,
  revolvingDoorGovEntity: GovEntityType[] | null,
  revolvingDoorLegislators: LegislatorType[] | null,
}


type NodeType = {
  "name": string,
  "children": NodeType[]
}


export default function processRevolvingDoorData(
  lobbyistData: LobbyistDataType[],
) {
  const categories:NodeType[] = [
    {
      "name": "Worked in Committee",
      "children": []
    },
    {
      "name": "Worked for a Government Entity",
      "children": []
    },
    {
      "name": "Worked for a Legislator",
      "children": [
        {
          "name": "Democrat",
          "children": []
        },
        {
          "name": "Republican",
          "children": []
        },
        {
          "name": "Independent",
          "children": []
        },
      ]
    },
    {
      "name": "No Prior Experience",
      "children": []
    }
  ];

  lobbyistData.forEach((lobbyist) => {
    // if this lobbyist has no prior experience
    if(
      lobbyist.revolvingDoorComm === null
      && lobbyist.revolvingDoorGovEntity === null
      && lobbyist.revolvingDoorLegislators === null
    ){
      categories[3].children.push({"name": lobbyist.name, children: []}) //push them into the no prior experience category
    }
    else {
      // Committees, Gov Entities, and Legislators
      [lobbyist.revolvingDoorComm, lobbyist.revolvingDoorGovEntity, lobbyist.revolvingDoorLegislators].forEach((category, categoryIndex) => {
        if(category !== null) {
          category.forEach((d: CommType | GovEntityType | LegislatorType) => {
            // Make lower case with caps at the front of each word
            let lobbyistName = lobbyist.name.toLowerCase().replace(/(^| )([A-Z])/ig, function(v) { return v.toUpperCase(); });

            //set node name
            let nodeName = "";
            if(categoryIndex === 0) nodeName = (d as CommType).committeeMostRecentName;
            else if(categoryIndex === 1) nodeName = (d as GovEntityType).govEntity;
            else if(categoryIndex === 2) nodeName = (d as LegislatorType).legislatorFirstName + " " + (d as LegislatorType).legislatorLastName;

            // If working for legislator, add party info
            if(categoryIndex === 2) {
              const party = (d as LegislatorType).legislatorsTerms[0].termParty;
              const partyIndex = ( //0 Democrat, 1 Republican, 2 Other
                party.toUpperCase() === "DEMOCRAT" ? 0 : (
                  party.toUpperCase() === "REPUBLICAN" ? 1 : 2
                )
              );

              const partyChildren = categories[categoryIndex].children[partyIndex].children

              let childIndex = partyChildren.findIndex((x) => x.name === nodeName);

              if(childIndex === -1){ // If this child doesn't yet exist, make a new one
                childIndex = partyChildren.length;
                partyChildren.push({"name": nodeName, "children": []});
              }

              partyChildren[childIndex].children.push({"name": lobbyistName, children: []});
            }
            else if ( //else if this node is not the senate or house
              String(nodeName).toUpperCase() !== "SENATE"
              && String(nodeName).toUpperCase() !== "HOUSE OF REPRESENTATIVES"
            ){ // Filter out Senate and House
              let childIndex = categories[categoryIndex].children.findIndex((x) => x.name === nodeName);

              if(childIndex === -1){ // If this child doesn't yet exist, make a new one
                childIndex = categories[categoryIndex].children.length;
                categories[categoryIndex].children.push({"name": nodeName, "children": []});
              }

              categories[categoryIndex].children[childIndex].children.push({"name": lobbyistName, "children": []});
            }
          })
        }
      });
    }
  });

  // Remove empty categories
  categories[2].children = categories[2].children.filter((c) => c.children.length > 0);

  const data = {
    "name": "Became Lobbyist",
    "children": categories.filter((c) => c.children.length > 0)
  };
  data.children.forEach(c => removeEmptyChildren(c))

  return d3Hierarchy(data).sort((a, b) => d3Ascending(a.data.name, b.data.name))
}

/**
 * given a node, recursively delete the "children" field if it is empty
 * @param  node
 */
function removeEmptyChildren(node: NodeType) {
  if(node.children.length === 0) { //if this node's children are empty
    //delete the children field
    //I (Harry) am not sure that this is necessary.
    //TypeScript doesn't like this because the NodeType has "children" as a required field.
    //It's easier to override TypeScript here,
    //rather than manually checking that "children" exists whenever we access the field.
    //@ts-ignore
    delete node.children
  }
  else {
    //else recursively check its children
    node.children.forEach(c => removeEmptyChildren(c))
  }
}
