import gql from 'graphql-tag';
import parseBillId from "utils/parseBillId/parseBillId";

export const AUTO_SUGGEST_BILL_ID = input => gql`
query AutoSuggestBillId($tentativeBillId:BillIdInput!, $tentativeCongressNumber:Int!) {
  topBills(
    tentativeBillId: $tentativeBillId,
    tentativeCongressNumber: $tentativeCongressNumber
  ) {
    candidateCongressSession
    billTotDistance
    candidateBillId {
      billChamber
      billNumber
      billResolutionType
    }
  }
}
`


export const AUTO_SUGGEST_BILL_ID_VARIABLES = input => {
  const parsedBillId = parseBillId(input, false); //get parsed request, not strict

  const tentativeBillId = {
    billChamber: parsedBillId.billChamber || undefined,
    billNumber: parsedBillId.billNumber || undefined,
    billResolutionType: parsedBillId.billResolutionType || undefined,
  }

  return {
    tentativeBillId,
    tentativeCongressNumber: parsedBillId.congressSession || undefined,
  }
}


export const AUTO_SUGGEST_BILL_TITLE = input => gql`
{
  bills(
    filter: {
      billTitlesByCongressNumberAndBillIdExist: true
      billTitlesByCongressNumberAndBillId: {
        some: { billTitle: { likeInsensitive: "%${input}%" } }
      }
    }
    first: 5
    orderBy: PRIMARY_KEY_DESC
  ) {
    congressNumber
    billId {
      billChamber
      billNumber
      billResolutionType
    }
    billTitlesByCongressNumberAndBillId {
      billTitle
      billTitleAs
      billTitleType
    }
  }
}
`
