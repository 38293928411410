import React  from 'react';

export default function formatLegendIssue (key, color) {
    const text = key==="other" ? "..." : key;

    return (
      <span style={{
        height: "22px",
        background: color,
        color:"white",
        borderRadius: "3px",
        fontFamily: "Bookman, Verdana, Arial",
        padding: "0.5em",
        paddingTop: "0.25em",
        paddingBottom: "0.3em",
      }}>{text}</span>
    );
}
