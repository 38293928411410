import gql from 'graphql-tag';

export default function getGovernmentAgenciesQuery(reportIds) {
  return (
    gql`
    query getamountPerGovEntityPerYear {
      vizGetGoventityYearAmount(
        jsonReportUuids: ${JSON.stringify(reportIds)}
      )
    }
    `
  )
}
