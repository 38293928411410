import React from 'react'
import PropTypes from 'prop-types'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

import download from "utils/download/download";

import Copyright from "components/copyright/Copyright";

class DownloadModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fileName: ""
    }
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{this.props.message}</div>

          <br/>

          <InputGroup>
            <FormControl
              placeholder="your-file-name"
              value={this.state.fileName}
              onChange={e => this.setState({fileName: e.target.value})}
            />
            <InputGroup.Append>
              <InputGroup.Text>.json</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Copyright/>

          <Button variant="primary" onClick={e => {
            download(
              this.props.fileDataType,
              this.props.getDataAsString(),
              this.state.fileName + this.props.fileExtension
            );
            this.props.hideModal()
          }}>
            Agree to License and Download <FontAwesomeIcon icon={faDownload}/>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

DownloadModal.propTypes = {
  show: PropTypes.bool.isRequired,
  getDataAsString: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,

  title: PropTypes.string,
  message: PropTypes.string,
  fileDataType: PropTypes.string,
  fileExtension: PropTypes.string,
}

DownloadModal.defaultProps = {
  title: "Download Reports Data",
  message: "What would you like to name your file?",
  fileDataType: "data:text/json;charset=utf-8",
  fileExtension: ".json",
}


export default DownloadModal
