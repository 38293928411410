import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

import "./timeSeriesContainer.scss"

import StackedAreaChart from 'stacked-area-chart-d3-react';
import memoizeOne from 'memoize-one';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import toPercent from "utils/toPercent/toPercent";
import isEmptyObject from "utils/isEmptyObject/isEmptyObject";
import shortenMoney, { shortenAndRoundMoney } from 'utils/shortenMoney/shortenMoney';
import formatMoney from 'utils/formatMoney/formatMoney';
import mapIssueCode from 'utils/mapIssueCode/mapIssueCode';

import processTimeSeriesData from './processTimeSeriesData/processTimeSeriesData'

const NUMBER_TOP_CODES = 6;

export default class TimeSeriesContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hoverIndex: null,
      clientX: null,
      clientY: null,

      selectAllIssuesIsChecked: true, //this toggle will allow a user to reset showing or hiding all issues
      filters: {} //uncheck a key only if it's filters[key] === false, otherwise the key is assumed to be checked
    };

    this.mobileLegendRef = React.createRef()
  }

  processYearIssueAmountData = memoizeOne(
    data => processTimeSeriesData(data, NUMBER_TOP_CODES)
  )

  getColor = memoizeOne(
    keys => d3.scaleOrdinal().domain(keys).range(["#EC7063", "#F39C12", "#F1C40F", "#2ECC71", "#3498DB", "#AF7AC5", "#7D3C98"])
  )

  clickArea = (e, key, dataIndex) => {
    let filtersCopy = {}
    this.timeSeriesData.keys.forEach(key => { //first assume all keys are ignored
      filtersCopy[key] = false
    })
    filtersCopy = Object.assign({}, filtersCopy, this.state.filters) //copy over the current state

    //if this is the filter key already, clear filters
    if(filtersCopy[key] === true) {
      filtersCopy = {}
    }
    else { //else filter to only show this key
      filtersCopy[key] = true
    }



    this.setState({
      filters: filtersCopy,
      selectAllIssuesIsChecked: !this.state.selectAllIssuesIsChecked
    })
  }

  toggleFiltersKey = key => {
    const filtersCopy = Object.assign({}, this.state.filters);
    filtersCopy[key] = filtersCopy[key] === false; //flip the filter for this key

    let checkedCount = 0; //count how many issues are checked
    this.timeSeriesData.keys.forEach(key => {
      //if the filter for this key is not false (ie is checked)
      if(filtersCopy[key] !== false) {
        checkedCount += 1; //increment count by 1
      }
    })

    //if all the issues are checked
    if(checkedCount === NUMBER_TOP_CODES+1) {
      this.setState({
        filters: filtersCopy,
        selectAllIssuesIsChecked: true
      })
    } else if (checkedCount > 0) { //if some of the issues are checked
      this.setState({
        filters: filtersCopy,
        selectAllIssuesIsChecked: false
      })
    }
  }

  toggleAllIssues = e => {
    //initialize a new object to save in state and a variable for checking if all issues are showing
    const filtersObject = {};
    let isAllTrue = true;

    //go thru the current filter state of all issues to check if they are all showing or not
    for (const issue in this.state.filters) {
      //if a single issue filter value is not true
      if (this.state.filters[issue] !== true) {
        isAllTrue = false //mark that not all issues are true
        break //break out of the loop and stop looking
      }
    }

    //if all the issues were true
    if (isAllTrue) {
      //manually set them all to false
      for (const issue of this.timeSeriesData.keys) {
        filtersObject[issue] = false
      }
    }
    //else filtersObject is already empty so we don't need to do anything

    this.setState({
      selectAllIssuesIsChecked: !this.state.selectAllIssuesIsChecked,
      filters: filtersObject
    })
  }

  onMouseOverHandler = (e, key, dataIndex) => {
    this.setState({
      hoverIndex: dataIndex
    })
  }

  onMouseMove = e => {
    this.setState({
      clientX: e.clientX,
      clientY: e.clientY
    })
  }

  getPopupLegendData = () => {
    const offset = 50;
    let style = {top: this.state.clientY, opacity: this.state.hoverIndex===null?0:1};
    let className = '';

    if(this.state.hoverIndex < (this.timeSeriesData.points.length-1)/2) { //if we are on the left side of the viz, put the legend to the right of our mouse
      style.left = this.state.clientX + offset;
      className = 'bs-tooltip-right';
    }
    else { //else put it to the left of our mouse
      style.right = `calc(100vw - ${this.state.clientX}px + ${offset}px)`;
      className = 'bs-tooltip-left';
    }
    return {
      style: style,
      className: className
    };
  }


  render() {
    this.timeSeriesData = this.processYearIssueAmountData(this.props.yearIssueAmountData)
    if(this.timeSeriesData.points.length > 0) {
      const colorFunction = this.getColor(this.timeSeriesData.keys)

      let filteredPoints = [] //filtered points to show on the viz
      let filteredKeys = [] //filtered keys to show on the viz
      if(!isEmptyObject(this.state.filters)) {//if there are filters
        filteredPoints = this.timeSeriesData.points.map(p => { //filter the points
          const filteredPoint = {} //initialize an empty object
          for(const field in p) { //loop through each field in this point and determine if we want to include it
            if(field==="date" || this.state.filters[field]!==false) { //if this is the date field OR the key's filter is !== to false
              filteredPoint[field] = p[field] //set key and value to include in the viz
            }
          }
          return filteredPoint
        })

        filteredKeys = this.timeSeriesData.keys.filter(key => this.state.filters[key]!==false) //filter the keys
      }
      else { //else, no filters, do nothing to the points and keys
        filteredPoints = this.timeSeriesData.points
        filteredKeys = this.timeSeriesData.keys
      }



      let legendTitle = ""
      if(this.timeSeriesData.points[this.state.hoverIndex]) { //if the user is hovering over one year
        legendTitle = this.timeSeriesData.points[this.state.hoverIndex].date.getFullYear(); //get the full year
      }
      else { //else the user is not hovering
        legendTitle = "Total Sum "+this.props.yearIssueAmountData[0].report_year; //indicate that this is the total, and also include the starting year

        if(this.props.yearIssueAmountData.length > 1) { //if there is more than one year
          legendTitle += " - " + this.props.yearIssueAmountData[this.props.yearIssueAmountData.length-1].report_year; //include the end year
        }
      }

      //do general calculations on all the points for the legend
      let total = 0; //total value to show on the legend
      let maxPointSum = 0; //used to calculate the max value shown on the viz in order to set the left margin
      let issuesSum = {}; //key-value object holding issues-summedAmounts to show on the legend

      for(let pointIndex=0; pointIndex<this.timeSeriesData.points.length; ++pointIndex) {
        let sum = 0;
        for(const issue in this.timeSeriesData.points[pointIndex]) {
          if(issue !== "date") {
            const value = this.timeSeriesData.points[pointIndex][issue]
            sum += value; //sum values for this point

            if(pointIndex===this.state.hoverIndex || this.timeSeriesData.points[this.state.hoverIndex]===undefined) { //if this is the year we are hovering over
              if(this.state.filters[issue] !== false){ //this accounts for whether the issue is specifically false or not to decide whether to add to the total value
                total += value; //sum total value
              }
              issuesSum[issue] = issuesSum[issue] ? issuesSum[issue]+value : value; //sum values for each issue
            }
          }
        }

        maxPointSum = Math.max(maxPointSum, sum); //set max point sum
      }
      const marginLeft = Math.max(20, shortenAndRoundMoney(maxPointSum).length*18); //cosmetic, add more margin if the y value is large

      //sort the issues so that "other" is always at the top, and the other issues are sorted ascending by size
      const sortedIssues = Object.keys(issuesSum).sort(function(a,b) {
        if(a === "other") return 1;
        if(b === "other") return -1;
        return issuesSum[a]>issuesSum[b] ? -1: 1;
      })

      const popUpLegend = this.getPopupLegendData();

      const xTicksSkip = Math.max(1, Math.ceil(100/(this.props.width/this.timeSeriesData.points.length)) ); //the number of ticks to skip varies invesely with space available per year


      return (
        <div className="timeSeriesContainer">
          <div className="nonMediumOnly" ref={this.mobileLegendRef} style={{position: "relative"}}>
            <div className={"tooltip " + popUpLegend.className} style={{
              position: "fixed",
              transform: "translateY(-50%)",
              ...popUpLegend.style
            }}
            >
              <div className="arrow" style={{top: '50%'}}></div>
              <div className="tooltip-inner">
                <PrimaryLegend
                  title={legendTitle}
                  total={total}
                  sortedIssues={sortedIssues}
                  selectAllIssuesIsChecked={this.state.selectAllIssuesIsChecked}
                  filters={this.state.filters}
                  toggleFiltersKey={this.toggleFiltersKey}
                  toggleAllIssues={this.toggleAllIssues}
                  colorFunction={colorFunction}
                  issuesSum={issuesSum}

                  showCheckbox={false}
                  showTooltip={false}
                />
              </div>
            </div>

            {sortedIssues.map(key =>
              <MobileOnlyKey key={key} issue={key} color={colorFunction(key)}/>
            )}
          </div>

          <div className="timeSeriesContent">
            <div className="timeSeriesChart" onMouseMove={this.onMouseMove} onMouseLeave={e => this.setState({clientX: null, clientY: null})}>
              <StackedAreaChart
                data={filteredPoints}
                keys={filteredKeys}

                color={colorFunction}
                height={this.props.height}
                margin={{top: 10, right: 0, bottom: 30, left: marginLeft}}
                yFormat={y => shortenMoney(y)}
                xTicksSkip={xTicksSkip}
                axisStroke="#ddd"
                axisStrokeWidth={1}
                onMouseOverHandler={this.onMouseOverHandler}
                onClickHandler={this.clickArea}
                dotsRadius={7}
              />
            </div>

            <div className="timeSeriesLegend">
              <div>
                <PrimaryLegend
                  title={legendTitle}
                  total={total}
                  sortedIssues={sortedIssues}
                  selectAllIssuesIsChecked={this.state.selectAllIssuesIsChecked}
                  filters={this.state.filters}
                  toggleFiltersKey={this.toggleFiltersKey}
                  toggleAllIssues={this.toggleAllIssues}
                  colorFunction={colorFunction}
                  issuesSum={issuesSum}

                  showCheckbox={true}
                  showTooltip={true}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    else if(this.props.requestStatus === "loading") {
      return "Loading...";
    }
    else if(this.props.requestStatus === "error") {
      return "There was an error getting the data. Please try again later.";
    }
    else if(this.props.requestStatus === "done") {
      return "There is no data from the query for this visualization";
    }
    else if(this.props.requestStatus === "") {
      return "Please use the form to get data for this visualization";
    }

    return "";
  }
}


const MobileOnlyKey = props => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          {props.issue==="other" ? "Other" : mapIssueCode(props.issue)}
        </Tooltip>
      }
    >
      <span className="timeSeriesMobileKey">
        <span className="timeSeriesMobileKeyDot" style={{background: props.color}}></span> {props.issue}
      </span>
    </OverlayTrigger>
  )
}

MobileOnlyKey.propTypes = {
  issue: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};



const PrimaryLegend = props => {
  return (
    <React.Fragment>
      <div>{props.title}:</div>
      <h4>${formatMoney(props.total)}</h4>
      <div className='custom-control custom-switch'>
        <input
          type='checkbox'
          className='custom-control-input'
          id='customSwitches'
          checked={props.selectAllIssuesIsChecked}
          onChange={props.toggleAllIssues}
        />
        <label className='custom-control-label' htmlFor='customSwitches'>
          Select All Issues
        </label>
      </div>
      <div>
        {props.sortedIssues.map(issue =>
          <PrimaryLegendKey
            key={issue}
            issue={issue}
            showCheckbox={props.showCheckbox}
            checked={props.filters[issue]!==false}
            toggleFiltersKey={props.toggleFiltersKey}
            color={props.colorFunction(issue)}
            amount={formatMoney(props.issuesSum[issue])}
            percentage={toPercent(props.issuesSum[issue], props.total, 2)}
            showTooltip={props.showTooltip}
          />
        )}
      </div>
    </React.Fragment>
  )
}

PrimaryLegend.propTypes = {
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
  total: PropTypes.number.isRequired,
  sortedIssues: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  selectAllIssuesIsChecked: PropTypes.bool.isRequired,
  toggleFiltersKey: PropTypes.func.isRequired,
  toggleAllIssues: PropTypes.func.isRequired,
  colorFunction: PropTypes.func.isRequired,
  issuesSum: PropTypes.object.isRequired,

  showCheckbox: PropTypes.bool.isRequired,
  showTooltip: PropTypes.bool.isRequired,
};



const PrimaryLegendKey = props => {
  const checkbox = props.showCheckbox ? (
    <React.Fragment><input type="checkbox" checked={props.checked} onChange={e => props.toggleFiltersKey(props.issue)}/> &nbsp;&nbsp;</React.Fragment>
  ) : null;

  const content = (
    <div className="timeSeriesLegendRow">
      {checkbox}
      {formatLegendIssue(props.issue, props.color)} &nbsp;
      ${props.amount} &nbsp;
      ({props.checked ? props.percentage+"%" : "-"})
    </div>
  )

  if(props.showTooltip) {
    return (
      <OverlayTrigger
        key={props.issue}
        placement="left"
        overlay={
          <Tooltip>
            {props.issue==="other" ? "Other" : mapIssueCode(props.issue)}
          </Tooltip>
        }
      >
        {content}
      </OverlayTrigger>
    )
  }

  return content
}

PrimaryLegendKey.propTypes = {
  showCheckbox: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  toggleFiltersKey: PropTypes.func.isRequired,
  issue: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  showTooltip: PropTypes.bool.isRequired,
};



const formatLegendIssue = (key, color) => {
  const text = key==="other" ? "etc" : key;

  return (
    <span style={{
      height: "22px",
      background: color,
      color:"white",
      paddingLeft: "3px",
      paddingRight: "3px",
      borderRadius: "2px",
      fontFamily: "monospace"
    }}>{text}</span>
  );
}

TimeSeriesContainer.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  yearIssueAmountData: PropTypes.array.isRequired,
  requestStatus: PropTypes.string.isRequired
}
